<template>
    <div id="sub_sms_port_import_id">
        <!-- 短信子端口号导入 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="true" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>短信子端口号导入</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">

                        <el-form-item label="导入文件">
                            <!-- show-file-list:是否显示已上传文件列表  默认值 true
                            multiple:是否支持多选文件 
                            data:上传时附带的额外参数
                            auto-upload	是否在选取文件后立即进行上传
                            with-credentials 是否支持发送 cookie 凭证信息  true:支持
                            -->
                            <el-upload ref="uploadFile" style="display: inline-block;"
                                    :action="upload_url" :multiple="true" :limit="1" :auto-upload="false"
                                    :with-credentials="true"
                                    accept=".xls,.xlsx"

                                    :on-success="uploadResponseResult"
                                    :on-error="uploadResponseResultFail"

                                    :file-list="fileList"
                                    
                                    :on-change="uploadChange"
                                    :on-remove="uploadChange"
                                    :before-upload="beforeAvatarUpload"
                                    >
                                <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    支持xls格式、xlsx格式，且上传文件大小限制最高4M
                                    <div>
                                        导入文件示例：<br/>
                                        <img src="@/assets/img/media/import.png"/>
                                    </div>
                                </div>
                                
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="登录手机号">
                            <el-input type="number" v-model="form_data.agency_phone" placeholder="请输入登录手机号" size="mini">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="接入省份">
                            <el-select v-model="form_data.province_name" placeholder="请选择接入省份" size="mini" style="width:100%" @change="bindProvinceChange"> 
                                <el-option
                                    v-for="item in provinceList"
                                    :key="item.id"
                                    :label="item.province_name"
                                    :value="item.id+'|'+item.province_name"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="接入城市">
                            <el-select v-model="form_data.city_name" placeholder="请选择接入城市" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in cityList"
                                    :key="item.city_name"
                                    :label="item.city_name"
                                    :value="item.city_name"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="企业名称">
                            <el-input v-model="form_data.company_name" placeholder="请输入企业名称" size="mini" type="text"></el-input>
                        </el-form-item>

                        <el-form-item label="企业统一社会信用代码">
                            <el-input v-model="form_data.company_num" placeholder="请输入企业统一社会信用代码" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="责任人名称">
                            <el-input v-model="form_data.regist_name" placeholder="请输入责任人名称" size="mini" type="text"></el-input>
                        </el-form-item>

                        <el-form-item label="责任人证件类型">
                            <el-select v-model="form_data.legal_cert_type" placeholder="请选择责任人证件类型" size="mini" style="width:100%"> 
                                <el-option label="身份证" value="身份证"></el-option>
                                <el-option label="护照" value="护照"></el-option>
                                <el-option label="港澳居民来往内地通行证" value="港澳居民来往内地通行证"></el-option>
                                <el-option label="台湾居民来往大陆通行证" value="台湾居民来往大陆通行证"></el-option>
                                <el-option label="港澳台居民居住证" value="港澳台居民居住证"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="责任人证件号码">
                            <el-input v-model="form_data.legal_cert_id" placeholder="请输入责任人证件号码" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="经办人名称">
                            <el-input v-model="form_data.agency_name" placeholder="请输入经办人名称" size="mini" type="text"></el-input>
                        </el-form-item>

                        <el-form-item label="经办人证件类型">
                            <el-select v-model="form_data.agency_cert_type" placeholder="请选择经办人证件类型" size="mini" style="width:100%"> 
                                <el-option label="身份证" value="身份证"></el-option>
                                <el-option label="护照" value="护照"></el-option>
                                <el-option label="港澳居民来往内地通行证" value="港澳居民来往内地通行证"></el-option>
                                <el-option label="台湾居民来往大陆通行证" value="台湾居民来往大陆通行证"></el-option>
                                <el-option label="港澳台居民居住证" value="港澳台居民居住证"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="经办人证件号码">
                            <el-input v-model="form_data.agency_cert_id" placeholder="请输入经办人证件号码" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="具体用途">
                            <el-input v-model="form_data.sub_sms_port_usage" placeholder="请输入具体用途" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="端口类型">
                            <el-select v-model="form_data.port_type" placeholder="请选择端口类型" size="mini" style="width:100%" @change="bindPortTypeChange"> 
                                <el-option
                                    v-for="item in portTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="用途类别">
                            <el-select v-model="form_data.usage_category" placeholder="请选择用途类别" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in usageCategoryList"
                                    :key="item.usage_category"
                                    :label="item.usage_category"
                                    :value="item.usage_category"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="签名来源">
                            <el-select v-model="form_data.signature_source" placeholder="请选择签名来源" size="mini" style="width:100%"> 
                                <el-option label="全称" value="全称"></el-option>
                                <el-option label="简称" value="简称"></el-option>
                                <el-option label="商标" value="商标"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="短信端口企业接入机房及设备">
                            <el-input v-model="form_data.location_server_room" placeholder="请输入短信端口企业接入机房及设备" size="mini" type="text"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini" :disabled="isAvailable == 1">保存</el-button>
                        </el-form-item>
                    </el-form>

                </el-row>

            </el-row>


        </el-dialog>
    </div>
</template>
<script>
import API from '../../../api/api';
import {
    getItem
} from '../../../api/tool.js';

export default {
    props:{
        prop_change_i: 0,
        prop_import_sms_port: '',//主端口号
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见
                setTimeout(() => {
                    this.form_data.sms_port = this.prop_import_sms_port;
                    //请求接口-获取短信主端口省份列表
                    this.getSmsPortProvinceList();
                    //请求接口-获取短信主端口城市列表
                    this.getSmsPortCityList();
                }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            provinceList:[],//省份列表
            cityAllList:[],//所有城市列表
            cityList:[],//所选城市列表

            usageCategoryList:[],//用途类别列表

            //端口类型列表
            portTypeList:[{
                value: 'A_商业性',
                label: 'A_商业性'
            }, {
                value: 'B_业务管理和服务类',
                label: 'B_业务管理和服务类'
            }, {
                value: 'C_公益性',
                label: 'C_公益性'
            }],
            //用途所有类别列表
            usageCategoryAllList:[{
                "port_type":"A_商业性",
                "usage_array":[
                    {
                        "usage_category":"A01-金融推销（包含贷款理财和保险推销）"
                    },{
                        "usage_category":"A02-零售业推销"
                    },{
                        "usage_category":"A03-电信业务宣传"
                    },{
                        "usage_category":"A04-网络游戏推广"
                    },{
                        "usage_category":"A05-教育培训推销"
                    },{
                        "usage_category":"A06-房产中介推销"
                    },{
                        "usage_category":"A07-股票证券推销"
                    },{
                        "usage_category":"A08-医疗卫生推销"
                    },{
                        "usage_category":"A09-猎头招聘推广"
                    },{
                        "usage_category":"A10-旅游推广"
                    },{
                        "usage_category":"A11-其它营销类别"
                    }]
                },{
                "port_type":"B_业务管理和服务类",
                "usage_array":[
                    {
                        "usage_category":"B01-操作验证"
                    },{
                        "usage_category":"B02-民生服务"
                    },{
                        "usage_category":"B03-业务提醒（非金融类）"
                    },{
                        "usage_category":"B04-业务提醒（金融类）"
                    },{
                        "usage_category":"B05-还款通知"
                    },{
                        "usage_category":"B06-其他业务管理服务类"
                    }]
                },{
                "port_type":"C_公益性",
                "usage_array":[
                    {
                        "usage_category":"C01-非应急公益性"
                    },{
                        "usage_category":"C02-应急公益性"
                    }]
                }
            ],

            form_data:{
                sms_port: "",//短信主端口号
                sub_sms_port: "",//短信子端口号
                agency_phone:'13003104570',//登录手机号
                province_name:'北京',//接入省份
                city_name:'北京市',//接入城市
                company_name:'上海河滔信息科技有限公司',//企业名称
                company_num:'91310105MA1FWRGU86',//企业统一社会信用代码
                regist_name:'曾春雪',//责任人名称
                legal_cert_type:'身份证',//责任人证件类型
                legal_cert_id:'341122198901165224',//责任人证件号码
                agency_name:'曾春雪',//经办人名称
                agency_cert_type:'身份证',//经办人证件类型
                agency_cert_id:'341122198901165224',//经办人证件号码
                sub_sms_port_usage:'行业通知和会员服务',//具体用途
                port_type:'A_商业性',//端口类型：枚举值按照附录六上报
                usage_category:'A11-其它营销类别',//用途类别：枚举值按照附录六上报
                signature_source:'商标',//签名来源
                sms_signature:'',//短信签名
                location_server_room:'四川联通天府信息机房，紫光云服务器',//短信端口企业接入机房及设备
            },
            upload_url:"",//上传url
            fileList:[],//文件列表

            isAvailable: 0,//保存按钮是否可用 0：可用 1：不可用
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //请求接口-获取短信主端口省份列表
        getSmsPortProvinceList(){
            API.OperationManagerServlet({
                param: "sms_port_province",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.provinceList = res.list;
                }
            });
        },
        //请求接口-获取短信主端口城市列表
        getSmsPortCityList(){
            API.OperationManagerServlet({
                param: "sms_port_city",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.cityAllList = res.list;
                }
            });
        },
        // 省
        bindProvinceChange(vals) {
            // 拿到省份对应的唯一编号
            // console.log('data========>', vals)
            let arr = vals.split('|')
            this.form_data.province_name = arr[1]
            this.form_data.city_name = '';
            // 拿到对应的城市
            for(var i = 0;i<this.cityAllList.length;i++){
                var one = this.cityAllList[i];
                if(one.province_id == arr[0]+""){
                    this.cityList = one.city_array;
                }
            }
        },
        //端口类型
        bindPortTypeChange(portType){
            // 拿到端口类型对应的唯一编号
            // console.log('data========>', portType)
            this.form_data.port_type = portType;
            this.form_data.usage_category = '';
            // 拿到对应的城市
            for(var i = 0;i<this.usageCategoryAllList.length;i++){
                var one = this.usageCategoryAllList[i];
                if(one.port_type == portType){
                    this.usageCategoryList = one.usage_array;
                }
            }
        },
        //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
        beforeAvatarUpload(file) {
            console.log(file);
            const imgType = file.name.endsWith('.xls')||file.name.endsWith('.xlsx')||file.name.endsWith('.XLS')||file.name.endsWith('.XLSX');
            const isLt2M = file.size / 1024  < 300;

            if (!imgType) {
                this.$message.error('上传文件只能是 xls或xlsx 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 4M!');
            }
            if(imgType && isLt2M){
                return new Promise((resolve, reject) => {
                    console.log(1);
                    // 拼接上传url
                    // 此处动态配置action URL
                    this.upload_url = API.getInterfaceUrl()+"/OperationManagerServlet?param=import_sub_sms_port&session="+getItem("qxt_session")
                    +"&agency_phone="+this.form_data.agency_phone+"&sms_port="+this.form_data.sms_port
                    +"&province_name="+encodeURIComponent(this.form_data.province_name)+"&city_name="+encodeURIComponent(this.form_data.city_name)
                    +"&company_name="+encodeURIComponent(this.form_data.company_name)+"&company_num="+this.form_data.company_num
                    +"&regist_name="+encodeURIComponent(this.form_data.regist_name)+"&legal_cert_type="+encodeURIComponent(this.form_data.legal_cert_type)
                    +"&legal_cert_id="+this.form_data.legal_cert_id+"&agency_name="+encodeURIComponent(this.form_data.agency_name)
                    +"&agency_cert_type="+encodeURIComponent(this.form_data.agency_cert_type)+"&agency_cert_id="+this.form_data.agency_cert_id
                    +"&sub_sms_port_usage="+encodeURIComponent(this.form_data.sub_sms_port_usage)+"&port_type="+encodeURIComponent(this.form_data.port_type)
                    +"&usage_category="+encodeURIComponent(this.form_data.usage_category)+"&signature_source="+encodeURIComponent(this.form_data.signature_source)
                    +"&location_server_room="+encodeURIComponent(this.form_data.location_server_room)
                    +"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                    console.log(2);
                    console.log("this.upload_url="+this.upload_url);
                    // dom上传地址更新完成后，触发上传
                    this.$nextTick(() => resolve());
                });
            }else{
                return imgType && isLt2M;
            }
        },
         //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            // console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            // console.log("上传响应结果res:"+JSON.stringify(res));
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            if(res){
                // res = JSON.parse(res);
                if(res.recode == 0){
                    this.isAvailable = 0;
                    var duration = 10000;
                    this.$message({
                        showClose: true,
                        message: res.text,
                        duration:duration,
                        type: 'success'
                    });
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                    this.$emit("add_sub_sms_port", "");
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }    
        },
        
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },
        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.sms_port)){
                this.$message.error("请输入短信主端口号");
                return;
            }

            if(API.isEmtry(this.form_data.agency_phone)){
                this.$message.error("请输入登录手机号");
                return;
            }
            if(API.isEmtry(this.form_data.province_name)){
                this.$message.error("请选择接入省份");
                return;
            }
            if(API.isEmtry(this.form_data.city_name)){
                this.$message.error("请选择接入城市");
                return;
            }
            if(API.isEmtry(this.form_data.company_name)){
                this.$message.error("请输入企业名称");
                return;
            }
            if(API.isEmtry(this.form_data.company_num)){
                this.$message.error("请输入企业统一社会信用代码");
                return;
            }
            if(API.isEmtry(this.form_data.regist_name)){
                this.$message.error("请输入责任人名称");
                return;
            }
            if(API.isEmtry(this.form_data.legal_cert_type)){
                this.$message.error("请选择责任人证件类型");
                return;
            }
            if(API.isEmtry(this.form_data.legal_cert_id)){
                this.$message.error("请输入责任人证件号码");
                return;
            }
            if(API.isEmtry(this.form_data.agency_name)){
                this.$message.error("请输入经办人名称");
                return;
            }
            if(API.isEmtry(this.form_data.agency_cert_type)){
                this.$message.error("请输入经办人证件类型");
                return;
            }
            if(API.isEmtry(this.form_data.agency_cert_id)){
                this.$message.error("请输入经办人证件号码");
                return;
            }
            if(API.isEmtry(this.form_data.port_type)){
                this.$message.error("请选择端口类型");
                return;
            }
            if(API.isEmtry(this.form_data.usage_category)){
                this.$message.error("请选择用途类别");
                return;
            }
            if(API.isEmtry(this.form_data.signature_source)){
                this.$message.error("请选择签名来源");
                return;
            }
            if(API.isEmtry(this.form_data.location_server_room)){
                this.$message.error("请输入短信端口企业接入机房及设备");
                return;
            }
            if(this.fileList.length == 0){
                this.$message.error("请选择文件");
            }else{
                this.isAvailable = 0;
                this.$refs.uploadFile.submit();
            }
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>