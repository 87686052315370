<template>
    <!-- 短信子端口列表 -->
    <div id="sub_sms_port_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    新增
                </el-button>
                <el-button type="primary" @click="import_file()" size="mini">
                    导入
                </el-button>
            </div>
            <div class="head_top_title">短信子端口号</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="sms_port" label="短信主端口号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="sub_sms_port" label="短信子端口号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="sms_signature" label="短信签名" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="agency_phone" label="登录手机号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="province_name" label="接入省份" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="city_name" label="接入城市" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="company_name" label="企业名称" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="company_num" label="企业统一社会信用代码" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="regist_name" label="责任人名称" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="legal_cert_type" label="责任人证件类型" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="legal_cert_id" label="责任人证件号码" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="agency_name" label="经办人名称" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="agency_cert_type" label="经办人证件类型" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="agency_cert_id" label="经办人证件号码" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="sub_sms_port_usage" label="具体用途" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="port_type" label="端口类型" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="usage_category" label="用途类别" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="signature_source" label="签名来源" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="location_server_room" label="短信端口企业接入机房及设备" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="file_id" label="结果id" min-width="80" align="center"> </el-table-column>
                    <!-- <el-table-column prop="state" label="审核结果" min-width="80" align="center"> </el-table-column> -->
                    <el-table-column prop="createtime" label="创建时间" min-width="80" align="center"> </el-table-column>

                    <el-table-column label="管理" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="upd_sub_sms_port(scope.row)">修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>

        <!-- 新增/修改短信子端口号 -->
        <SubSmsPortAddUpd 
        :prop_change_i="prop_sub_sms_port_i" 
        :prop_type="prop_sub_sms_port_type"
        :prop_item="prop_sub_sms_port_item" 
        @add_sub_sms_port="sub_sms_port_list"></SubSmsPortAddUpd>

        <!-- 导入子端口号 -->
        <SubSmsPortImport :prop_change_i="prop_import_sms_port_i" :prop_import_sms_port="prop_import_sms_port"
        @add_sub_sms_port="sub_sms_port_list"></SubSmsPortImport>

    </div>
</template>
<script>
import API from '../../../api/api';
import SubSmsPortAddUpd from './sub_sms_port_add_upd.vue';//组件 新增子端口号
import SubSmsPortImport from './sub_sms_port_import.vue';//组件 新增子端口号

export default {

    components: {
        SubSmsPortAddUpd,
        SubSmsPortImport
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            tableData:[],//表格数据

            sms_port: '',

            //新建短信子端口号
            prop_sub_sms_port_i: 0,//新建短信子端口号  组件 弹出框是否可见
            prop_sub_sms_port_type:'add',//新建短信子端口号增加或修改  组件 类型  add：增加  upd:修改
            prop_sms_port:'',//主端口号
            prop_sub_sms_port_item:'',//新建/修改短信子端口号对象

            prop_import_sms_port_i:0,
            prop_import_sms_port:"",
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.sms_port = this.$route.params.id;
        },
        //查询
        getData(){
            //请求接口
            API.OperationManagerServlet({
                param: "sub_sms_port_list",
                sms_port: this.sms_port,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //返回
        goBack() {
            API.router_to("/sms_port");
        },
        //添加
        add(){
            this.prop_sub_sms_port_i ++;//弹出框是否可见
            this.prop_sub_sms_port_type = 'add';
            var obj = {sms_port:this.sms_port};
            this.prop_sub_sms_port_item = obj;
        },
        //导入
        import_file(){
            this.prop_import_sms_port = this.sms_port;
            this.prop_import_sms_port_i ++;//弹出框是否可见
        },
        //修改
        upd_sub_sms_port(item){
            this.prop_sub_sms_port_i ++;//弹出框是否可见
            this.prop_sub_sms_port_type = 'upd';
            this.prop_sub_sms_port_item = item;
        },
        //添加子端口后回调
        sub_sms_port_list(){
            this.getData();
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>